.uspExpanded {
	display: flex;
	flex-direction: column;
}

.uspImage {
	display: block;
}

.image {
	height: 'auto';
	width: 'auto';
	min-width: '100%';
}

.uspTitle {
	line-height: 1.25;
	margin-top: 16px;
	margin-bottom: 8px;
}

.uspDescription {
	@mixin fontFamily font-body;
	font-size: 0.875rem;
	margin: 0;
	line-height: 1.54;
}

.icon {
	display: none;
}

@media (--mobile) {
	.uspImage img {
		display: none;
	}

	.uspExpanded {
		flex-direction: row;
	}
	.uspContent {
		flex-direction: column;
	}

	.icon {
		display: inline;
		color: var(--sea);
		margin-top: 16px;
		width: 16px;
		height: 16px;
	}

	.uspTitle {
		padding-left: 32px;
		position: relative;
	}

	.uspDescription {
		margin-left: 16px;
		padding-left: 16px;
	}
}
