.starRating {
	display: flex;
	justify-content: flex-start;
	margin: 0;
	align-items: center;
	list-style-type: none;
}

.starRating li {
	margin-right: 2px;
}

.star {
	font-size: 0.8rem;
	color: var(--yellow-orange);
	width: 0.8rem;
}
