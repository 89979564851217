.quickSearchHeader {
	position: relative;
	height: 480px;
	margin-bottom: 50px;
}

.headerImage {
	opacity: 0.95;
	border-radius: var(--border-radius);
	overflow: hidden;
	object-fit: cover;
}

.container {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 1;
	border-radius: 20px;
	background-image: linear-gradient(
		122deg,
		rgba(19, 107, 93, 0) 24%,
		rgba(19, 107, 93, 0.5) 43%,
		rgba(19, 107, 93, 0.05) 63%
	);
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	align-items: center;

	@media (--mobile) {
		overflow: hidden;
	}
}

.title {
	margin-top: 85px;
	font-size: 3.5rem;
	color: var(--white);
	@mixin fontFamily font-body;
	font-weight: 900;
	text-align: center;
	display: block;
	line-height: 3.5rem;
}

.quickSearch {
	position: relative;
	padding-bottom: 85px;
	display: flex;
	align-self: center;
	flex-direction: column;
	width: stretch;

	&:before {
		background-image: url('./assets/flare.svg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position-y: -80px;
		background-position-x: 130px;
		position: absolute;
		margin-left: 190px;
		height: 480px;
		width: 1200px;
		z-index: -1;
		opacity: 0.95;
		content: ' ';
		bottom: 0;
		right: 0;
	}
}

.quickSearchTitle {
	font-size: 1.25rem;
	color: var(--white);
	@mixin fontFamily font-heading;
	font-weight: 400;
	display: block;
	margin-bottom: 10px;
}

.containerSearchInput {
	/* autoprefixer grid: autoplace */
	display: grid;
	grid-template-columns: 2fr 1fr 1fr 1fr;
	grid-template-rows: auto;
	grid-gap: 16px;

	& > * {
		height: 46px;
	}
}

@media (--tablet) {
	.containerSearchInput {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

@media (--mobile) {
	.quickSearchHeader {
		height: 580px;
		button {
			line-height: 0.9;
		}
	}

	.title {
		font-size: 2rem;
		padding: 0 20px;
	}

	.quickSearch {
		padding: 0 20px 20px;
		position: relative;

		&:before {
			background-position-y: -30px;
			background-position-x: -430px;
			height: 380px;
			width: 900px;
			left: -110px;
			bottom: 0;
		}
	}

	.quickSearchTitle {
		font-size: 1rem;
	}

	.containerSearchInput {
		grid-template-columns: auto;
		grid-template-rows: 1fr 1fr 1fr 1fr;
		flex-direction: column;
		padding-bottom: 20px;

		&:before {
			background-image: none;
		}
	}
}
