.rating {
	display: flex;
	align-items: center;
	z-index: 1;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: color-mod(var(--white) alpha(0.3));
		filter: blur(10%);
		box-shadow: 0 0 20px 20px color-mod(var(--white) alpha(0.3));
	}
}

.ratingContent {
	text-align: right;
	margin-right: 8px;
	filter: blur(0);
}

.ratingContent p {
	margin: 0;
}

.ratingGrade {
	@mixin fontFamily font-heading;
	font-weight: 400;
	&.normal {
		color: var(--blue-green);
	}

	&.good {
		color: var(--sea);
	}

	&.excellent {
		color: var(--light-green);
	}
}

.ratingCount {
	color: var(--black-grey);
	font-size: 0.6rem;
	font-weight: bold;
}

.ratingScore {
	filter: blur(0);
	border-radius: var(--border-radius);
	padding: 12px 12px 12px;

	&.normal {
		background-color: var(--blue-green);
	}

	&.good {
		background-color: var(--sea);
	}

	&.excellent {
		background-color: var(--light-green);
	}
}

.ratingScoreText {
	margin: 0;
	color: var(--white);
	@mixin fontFamily font-heading;
	font-weight: 400;
	font-size: 1.17rem;
}
