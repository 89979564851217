.chip {
	display: inline-flex;
	padding: 6px 9px;
	margin-right: 4px;
	margin-bottom: 4px;
	@mixin fontFamily font-body;
	font-size: 0.7rem;
	background-color: var(--white);
	border: 1px solid var(--very-light-pink);
	border-radius: 12px;
	align-items: center;
	flex-wrap: wrap;

	.icon {
		position: relative;
		top: -1px;
		margin-right: 5px;
		width: 0.75rem;
		height: 0.75rem;
		fill: var(--sea);

		&.faPlane {
			top: 0;
		}
	}

	.label {
		margin-right: 4px;
		color: var(--sea);
		@mixin fontFamily font-heading;
		font-size: 0.625rem;
		font-weight: 400;
		text-transform: uppercase;

		&--danger {
			color: var(--orange);
		}
	}

	.note {
		margin-left: 4px;
		color: var(--brown-grey-two);
	}
}
