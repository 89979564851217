.searchResultsContainer {
	max-width: 1000px;
	margin: 0 auto;
}

.searchResultsContainerRecommendations {
	max-width: 1280px;
}

.searchResults {
	/* autoprefixer grid: autoplace */
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(5, auto);
	grid-column-gap: 27px;
	grid-row-gap: 40px;
}

.searchResultsRecommendations {
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: repeat(1, auto);
}

@media (--mobile) {
	.searchResults {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(10, auto);
		grid-row-gap: 16px;
		grid-column-gap: 0;
	}
	.searchResultsRecommendations {
		grid-template-rows: repeat(1, auto);
	}
}
