.overlayPosition {
	&:before {
		background-position: right -10px top -45px;
	}

	&.noFlare:before {
		background: none;
	}
}

.cardHeader {
	background-color: var(--very-light-grey);
	position: relative;
	height: 240px;
	overflow: hidden;

	.rating {
		position: absolute;
		right: 16px;
		top: 16px;
		z-index: 2;
	}
}

.cardContext {
	padding: 16px 16px 8px;
	margin-top: -27px;
	position: relative;
	color: var(--black);
	display: flex;
	@mixin fontFamily font-body;
	flex-direction: column;
	flex: auto;

	a {
		text-decoration: none;
	}

	.hotelTitle {
		display: inline-block;
		margin: 10px 0 8px;

		.hotelTitleName {
			margin-right: 5px;
		}

		span {
			display: inline;

			ul {
				display: inline-flex;
			}
		}
	}

	.chipsContainer {
		margin-bottom: 12px;
	}

	.calamitiesText {
		font-size: 0.625rem;
		margin: 5px 0 0;
		color: var(--brown-grey);
	}

	button {
		margin-bottom: 4px;
	}
}

.card {
	display: flex;
	flex-direction: column;
	flex: auto;
}

.cardFooter {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	flex: auto;
}

.skeletonProductCard {
	pointer-events: none;
	.cardContext {
		margin-top: 0;

		.hotelTitle div,
		.location,
		.button,
		.calamitiesText {
			border-radius: 2px;
		}

		.hotelTitle div,
		.location,
		.calamitiesText {
			animation: skeletonFlash 2s infinite;
		}
	}

	.hotelTitle {
		margin-top: 14px;
		margin-bottom: 18px;

		div {
			height: 14px;
			background-color: color-mod(var(--sea) alpha(0.75));
			width: 93%;
		}
	}

	.location,
	.calamitiesText {
		height: 6px;
		background-color: var(--very-light-pink);
	}

	.location {
		margin-bottom: 16px;
		width: 31%;
	}

	.button {
		margin-bottom: 10px;
		width: 100%;
		height: 54px;
		background-color: var(--yellow-orange);
	}

	.calamitiesText {
		width: 38%;
	}

	.label {
		font-size: 0.7rem;
	}
}

.hasError {
	border-color: var(--error-skeleton-bar);

	.cardHeader {
		background-color: var(--error-skeleton-bg);
	}

	.cardContext {
		.hotelTitle div,
		.location,
		.calamitiesText {
			animation: none;
		}

		.hotelTitle div {
			background-color: var(--error-skeleton-bg);
		}

		.button {
			background-color: var(--error-skeleton-bg);
		}
	}
}

.placeholder {
	max-width: 25% !important;
	max-height: 25% !important;
	min-width: auto !important;
	min-height: auto !important;
	width: auto !important;
	height: auto !important;
}

@media (--mobile) {
	.cardHeader {
		height: 160px;
	}

	.hotelTitle {
		font-size: 16px;

		.ratingSpan {
			margin-top: 5px;
			margin-left: 0 !important;
		}
	}
	.skeletonProductCard {
		margin: 0 auto;
		min-width: 80%;
	}

	/* Override label size */
	.label {
		font-size: 0.7rem;
	}
}

@keyframes skeletonFlash {
	10%,
	90% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}
}
