/* autoprefixer grid: autoplace */
.upsContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: auto;
	grid-column-gap: 40px;
	grid-row-gap: 40px;
}

.uspExpandedCollectionTitle {
	text-align: center;
}

@media (--desktop) {
	.upsContainer {
		grid-column-gap: 10px;
		grid-row-gap: 10px;
	}
}

@media (--tablet) {
	.upsContainer {
		grid-template-columns: 1fr 1fr;
		-ms-grid-columns: 1fr 1fr;
		margin: 0 auto;
		max-width: 50em;
	}
}

@media (--mobile) {
	.upsContainer {
		grid-template-columns: 1fr;
		-ms-grid-columns: 1fr;
		grid-column-gap: 0;
		grid-row-gap: 0;
	}
}
