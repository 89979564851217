.overlayPosition {
	&:before {
		background-position: right -10px top -45px;
	}

	&.noFlare:before {
		background: none;
	}
}

.image {
	object-fit: 'cover';
}

.imageContainer {
	background-color: var(--very-light-grey);
	position: relative;
	height: 240px;
	overflow: hidden;

	.rating {
		position: absolute;
		right: 16px;
		top: 16px;
		z-index: 2;
	}
}
